import React from 'react'
import { FeaturePage } from '../components/FeaturePage'

export default () => (
  <FeaturePage
    heading1="Store, manage, and maximise your retail stock across your estate"
    heading2="Bid goodbye to monthly spreadsheets and manual errors. Trybe processes and accelerates your in-house product sales"
    heading3="Streamline your inventory management"
    headerBlocks={[
      "Eliminate manual errors by bringing your physical stock count across all locations into our cloud-based tool",
      "Sell your retail products through Trybe both within the software for in-person sales and also by upselling on your website during the bookings process",
      "Track, purchase, and receive inventory across your estate at the click of a button",
      "Enhance team productivity by creating and saving purchase order templates for your retail products",
    ]}
    featurePageBlocks={[
      {
        chip: 'Real-time auditing and adjustments',
        heading: 'Don’t allow your retail product audits and stock takes to get on top of you',
        body: [
          "Handle all your Inventory Management needs within Trybe. Simply allow your staff to perform routine stock checks and counts in real time across your estate. This will be visible to the rest of the team allowing them to make smarter, more informed decisions in regards to product utilisation and sales.",
        ]
      },
      {
        chip: 'Inventory tracking',
        heading: 'Streamline your inventory admin by having complete visibility on all steps of the process',
        body: [
          'Stock levels fluctuate but your control of the situation doesn’t need to mirror this. Your staff on the ground just log usage and sales allowing for you to monitor your stock levels and balances at the click of a button within our reporting.',
        ],
      },
      {
        chip: 'Purchasing and receiving',
        heading: 'Provide clarity and structure to your inventory records',
        body: [
          'Track in-house usage and sales within Trybe. We’ll then automatically update this within your system where you can monitor this against your current product levels.',
          "In a similar fashion, your team can easily record received products too.",
        ],
      },
      {
        chip: 'Vendor management',
        heading: 'Stay organised and keep all your supplier details in one place',
        body: [
          'Organise, manage and protect all of your sensitive supplier data in a safe and secure central location. Log their contact details and then track this against costs, reorder levels, and products sold.'
        ],
      }
    ]}
  />
)
